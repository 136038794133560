﻿"use strict";

/* Controllers */
var _START_SHOWING_COUNT = 100;
var _CURRENT_SHOWING_COUNT = 100;
var _MAX_SHOWING = false;

angular
    .module("AccountManagerApp")
    .controller("NetworksController", function NetworksController($scope, $rootScope, $timeout, $http) {
        $scope.currentNetwork = null;
        $scope.editDisabled = true;
        $scope.editMode = false;
        $scope.showAddNewNetwork = false;
        $scope.newNetwork = null;
        $scope.addLoading = false;
        $scope.loading = false;

        $scope.orgDesc = "";
        $scope.orgUrl = "";
        $scope.orgLink = "";
        $scope.orgLogo = "";
        $scope.orgName = "";

        $scope.loadNetworks = function (callback) {
            $http({ method: "GET", url: "api/ads/scripttags" }).success(function (data) {
                $scope.networks = data;
                for (var i = 0; i < $scope.networks.length; i++) {
                    $scope.networks[i].locked = true;

                    if ($scope.networks[i].logo) {
                        if (
                            $scope.networks[i].logo.indexOf("https://") === -1 &&
                            $scope.networks[i].logo.indexOf("http://") === -1
                        )
                            $scope.networks[i].logo =
                                "https://app.bannerflow.com/Content/img/networks/" +
                                $scope.networks[i].logo;
                    }
                }

                if (callback) callback();
            });
        };

        $scope.addNewNetwork = function () {
            $scope.addLoading = true;
            var data = $scope.newNetwork;

            var json = JSON.stringify(data);

            var formdata = new FormData();
            formdata.append("data", json);

            if ($scope.addNetworkImageFile) formdata.append("logo", $scope.addNetworkImageFile);

            var xhr = new XMLHttpRequest();

            xhr.open("POST", window.bf_legacy_url + "/api/ads/scripttags");
            xhr.setRequestHeader("Authorization", "Bearer " + window.token);

            xhr.send(formdata);
            var requestBeenHandled = false;
            xhr.onreadystatechange = function () {
                if (!requestBeenHandled) {
                    if (xhr.status == 200) {
                        $scope.loadNetworks(function () {
                            $timeout(function () {
                                $scope.$apply(function () {
                                    $scope.currentNetwork = null;
                                    $scope.addLoading = false;
                                    $scope.editMode = false;
                                    $scope.addNetworkImageFile = null;
                                    $scope.addNetworkImage = null;
                                    $scope.cancelAddNewNetwork();
                                });
                            }, 100);
                        });
                    } else {
                        window.location = "/Oops";
                    }
                }
                requestBeenHandled = true;
            };
        };

        $scope.cancelAddNewNetwork = function () {
            $scope.showAddNewNetwork = false;
            $scope.networkImageFile = null;
            $scope.networkImage = null;
            $scope.resetNewNetwork();
        };

        $scope.editNetwork = function (network) {
            $scope.currentNetwork = network;

            $scope.orgDesc = $scope.currentNetwork.description;
            $scope.orgUrl = $scope.currentNetwork.supportUrl;
            $scope.orgTag = $scope.currentNetwork.embedTagPattern;
            $scope.orgLogo = $scope.currentNetwork.logo;
            $scope.orgName = $scope.currentNetwork.name;
            $scope.currentNetwork.newName = $scope.currentNetwork.name;

            $scope.currentNetwork.locked = false;
            $scope.editMode = true;
        };

        $scope.saveNetwork = function () {
            $scope.loading = true;
            $scope.currentNetwork.name = $scope.currentNetwork.newName;

            var data = $scope.currentNetwork;

            delete data.logo;

            var json = JSON.stringify(data);

            var formdata = new FormData();
            formdata.append("data", json);

            if ($scope.networkImageFile) formdata.append("logo", $scope.networkImageFile);

            var xhr = new XMLHttpRequest();

            xhr.open("POST", window.bf_legacy_url + "/api/ads/scripttags");
            xhr.setRequestHeader("Authorization", "Bearer " + window.token);

            xhr.send(formdata);
            var requestBeenHandled = false;
            xhr.onreadystatechange = function () {
                if (!requestBeenHandled) {
                    if (xhr.status == 200) {
                        $scope.loadNetworks(function () {
                            $timeout(function () {
                                $scope.$apply(function () {
                                    $scope.currentNetwork = null;
                                    $scope.loading = false;
                                    $scope.editMode = false;
                                    $scope.networkImageFile = null;
                                    $scope.networkImage = null;
                                });
                            }, 100);
                        });

                        //xhr.abort();
                    } else {
                        window.location = "/Oops";
                    }
                }
                requestBeenHandled = true;
            };

            $scope.cancelAddNewNetwork();
        };

        $scope.enableNetwork = function (network) {
            $scope.editNetwork(network);
            $scope.loading = true;
            network.state = 0;

            var json = JSON.stringify(network);

            var formdata = new FormData();
            formdata.append("data", json);

            var xhr = new XMLHttpRequest();

            xhr.open("POST", window.bf_legacy_url + "/api/ads/scripttags");
            xhr.setRequestHeader("Authorization", "Bearer " + window.token);

            xhr.send(formdata);
            var requestBeenHandled = false;
            xhr.onreadystatechange = function () {
                if (!requestBeenHandled) {
                    if (xhr.status == 200) {
                        $timeout(function () {
                            $scope.$apply(function () {
                                $scope.editMode = false;
                                $scope.loading = false;
                                $scope.cancelEdit();
                            });
                        }, 100);

                        //xhr.abort();
                    } else {
                        window.location = "/Oops";
                    }
                }
                requestBeenHandled = true;
            };
        };

        $scope.deleteNetwork = function () {
            var disabledNetwork = angular.copy($scope.currentNetwork);
            disabledNetwork.state = 1;
            $scope.loading = true;

            var json = JSON.stringify(disabledNetwork);

            var formdata = new FormData();
            formdata.append("data", json);

            var xhr = new XMLHttpRequest();

            xhr.open("POST", window.bf_legacy_url + "/api/ads/scripttags");
            xhr.setRequestHeader("Authorization", "Bearer " + window.token);

            xhr.send(formdata);
            var requestBeenHandled = false;
            xhr.onreadystatechange = function () {
                if (!requestBeenHandled) {
                    if (xhr.status == 200) {
                        $timeout(function () {
                            $scope.$apply(function () {
                                $scope.loading = false;
                                $scope.currentNetwork.state = 1;
                                $scope.currentNetwork.locked = true;
                                $scope.editMode = false;
                            });
                        }, 100);

                        //xhr.abort();
                    } else {
                        window.location = "/Oops";
                    }
                }
                requestBeenHandled = true;
            };
        };

        $scope.cancelEdit = function () {
            $scope.currentNetwork.description = $scope.orgDesc;
            $scope.currentNetwork.supportUrl = $scope.orgUrl;
            $scope.currentNetwork.embedTagPattern = $scope.orgTag;
            $scope.currentNetwork.logo = $scope.orgLogo;
            $scope.currentNetwork.name = $scope.orgName;
            $scope.currentNetwork.locked = true;
            $scope.editMode = false;
            $scope.networkImageFile = null;
            $scope.networkImage = null;
        };

        $scope.resetNewNetwork = function () {
            $scope.newNetwork = {
                name: "",
                description: "",
                supportUrl: "",
                embedTagPattern: "",
                default: false,
                state: 0
            };
        };
        $scope.resetNewNetwork();

        $scope.loadNetworks();

        // Manual network management (global)
        $scope.parseExclusives = function (text) {
            var exclusiveOn = [];
            if (text !== "") {
                var exclusives = text.trim().split(/\s*,\s*/);
                for (var i = 0; i < exclusives.length; i++) {
                    var brandslugs = [];
                    var accountBrand = exclusives[i].split(":");
                    if (accountBrand.length >= 2) {
                        for (var j = 1; j < accountBrand.length; j++) {
                            if (accountBrand[j] !== "") {
                                brandslugs.push(accountBrand[j]);
                            }
                        }
                        exclusiveOn.push({ accountSlug: accountBrand[0], brandSlugs: brandslugs });
                    } else {
                        exclusiveOn.push({ accountSlug: accountBrand[0], brandSlugs: [] });
                    }
                }
            }

            return exclusiveOn;
        };
    });
